module.exports = {
  LAZYLOAD_ON: 'on',
  LAZYLOAD_OFF: 'off',
  LAZYLOAD_SSRONLY: 'ssrOnly',
  LAZYLOAD_WHEN_IDLE: 'whenIdle',
  LAZYLOAD_WHEN_VISIBLE: 'whenVisible',
  STATIC_ITEM: 'STATIC_ITEM',
  STATIC_ICON: 'STATIC_ICON',
  STATIC_MEDIA: 'STATIC_MEDIA',
  STATIC_ILLUSTRATION: 'STATIC_ILLUSTRATION',
  ITEMS_IMAGES_GRID: 'ITEMS_IMAGES_GRID',
  ALL_ITEMS_IMAGES: '4',
  TYPE_PRICE: 'price',
  TYPE_ICON: 'icon',
  INTERPOLATION_REGEXP: /{([\s\S]+?)}/g,
  DISCOUNT_FONT_SIZE: 10,
  DYNAMIC_ACCESS_SIZE: {
    SMALL: 'small',
    MEDIUM: 'medium',
    LONG: 'long',
    NEW_LONG: 'new-long',
  },
  DYNAMIC_ACCESS_MODAL: [
    'payment_methods',
    'payment_methods_v2',
  ],
  CARD_CUSTOM_IFRAME_MODAL: [
    'payment_methods_v2',
  ],
  DYNAMIC_ACCESS_CUSTOM_CARD: [
    'location',
  ],
  SVG_SPRITES: 'svg-sprites-icons',
};
