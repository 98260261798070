const React = require('react');
const { arrayOf, shape, string } = require('prop-types');

const RegulationRow = require('../row-regulation/mobile');
const LazyComponent = require('../lazy-component');
const ErrorBoundaryComponent = require('../error-boundary/component');

const REGULATION_ROW = 'regulation-row';

function ComponentList({ list, ...childrenProps }) {
  return (
    <div>
      {list.map((component, i) => {
        if (component.type === REGULATION_ROW && component.components.length) {
          return (
            <RegulationRow
              key={component.id || component.type}
              componentProps={component}
              childrenProps={childrenProps}
            />
          );
        }

        return (
          <ErrorBoundaryComponent componentType={component.type} key={component.id || component.type}>
            <LazyComponent
              lazyLoad={false}
              {...component}
              {...childrenProps}
              elementOrder={i}
            />
          </ErrorBoundaryComponent>
        );
      })}
    </div>
  );
}

ComponentList.propTypes = {
  list: arrayOf(
    shape({
      id: string,
      type: string.isRequired,
    }),
  ).isRequired,
};

module.exports = ComponentList;
