/* eslint-env browser */

const React = require('react');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { loadableReady } = require('nordic/lazy');
const { hydrateRoot } = require('nordic/hydrate');
const { ImageProvider } = require('nordic/image');
const restClient = require('./services/rest-client');

module.exports = function startApp(Component) {
  const preloadedState = window.ML_PRELOADED_STATE;


  const i18n = new I18n({ translations: preloadedState.translations });
  const restClientWithToken = restClient({
    deviceType: preloadedState.deviceType,
    params: { _csrf: preloadedState.csrfToken },
  });

  loadableReady(() => {
    hydrateRoot(
      document.getElementById('root-app'),
      <ImageProvider prefix={preloadedState.imagesPrefix}>
        <I18nProvider i18n={i18n}>
          <Component {...preloadedState} restClient={restClientWithToken} />
        </I18nProvider>
      </ImageProvider>,
    );
  });

  window.newrelic?.setErrorHandler((err) => {
    const ignoreStatusMessages = [
      'Script error.',
    ];
    let response = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ignoreStatusMessages.length; i++) {
      const element = ignoreStatusMessages[i];
      if (err.message === element) response = true;
    }

    return response;
  });
};
