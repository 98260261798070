/* istanbul ignore file */

const React = require('react');
const { bool, object, oneOf, string, node, array, shape, number, func } = require('prop-types');
const MelidataTrack = require('nordic/melidata/melidata-track');
const { Script } = require('nordic/script');
const { LazyHydrate: Hydrator } = require('nordic/lazy');
const Style = require('nordic/style');
const { loadable } = require('nordic/lazy');
const classNames = require('classnames');
const Head = require('nordic/head');
const injectI18n = require('nordic/i18n/injectI18n');
const { AndesProvider } = require('@andes/context');

const Hotjar = require('./hotjar');
const Schema = require('../../commons/schema');
const MeliMessages = require('./meli-messages');
const Header = require('../../commons/header');
const TrackNewrelic = require('../../commons/track-newrelic');
const ComponentList = require('../../commons/component-list/mobile');
const { getCriticalCHScript } = require('../../../../utils/critical-ch');
const { SVG_SPRITES } = require('../../../../utils/constants');

const RedirectApp = loadable(() => import('redirect-app-web'));
const Interstitial = loadable(() => import('./loyalty-interstitial'));

function Home({
  atf,
  csrfToken,
  deviceType,
  layout,
  lowEnd,
  restClient,
  siteId,
  canonical,
  hotjar,
  mainDomain,
  children,
  newrelicTransactionName,
  trackExperiments,
  ssrComponents,
  isServerSide,
  preloadRecommendationsImgs,
  currentUser,
  melidataEventData,
  melidataPath,
  i18n,
  userConsentCookie,
  criticalCH,
  openInApp,
  country,
  nonce,
  viewportWidth,
  serializeProps,
}) {
  const forwardProps = {
    lowEnd,
    restClient,
    deviceType,
    layout,
    siteId,
    canonical,
    metadata: atf.metadata,
    bookmarks: atf.bookmarks,
    isServerSide,
    currentUser,
    userConsentCookie,
    country,
    viewportWidth,
    nonce,
  };

  const list = ssrComponents || atf.components;
  const title = i18n.gettext('Mercado Libre');
  const isPolycard = list.some((reco) => reco.is_polycard);

  return (
    <div className={classNames('home', { 'low-end': lowEnd })}>
      {openInApp && openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
      <Head>
        <Schema schemaData={atf.schemadata} />
      </Head>
      <MelidataTrack path={melidataPath} type="view" experiments={trackExperiments} event_data={melidataEventData} />
      <Header
        {...atf.header}
        canonical={canonical}
        siteId={siteId}
        preloadRecommendationsImgs={preloadRecommendationsImgs}
      />
      <Style href={`home.${deviceType}.css`} critical={deviceType === 'mobile'} />
      <Style
        href={
          isPolycard
            ? 'polycard-web-lib.css'
            : `recommendations-fe-${deviceType}.css`
        }
        critical={deviceType === 'mobile'}
      />
      {(criticalCH && criticalCH.allowedParticipant) && <Script on="now">{getCriticalCHScript(criticalCH)}</Script>}
      <Script priority={1}>{`window.ML_PRELOADED_STATE = ${serializeProps};`}</Script>
      <h1 className="clipped">{title}</h1>
      {atf.interstitial && <Interstitial deviceType={deviceType} {...atf.interstitial} />}
      <AndesProvider locale={country?.locale?.replace('_', '-')} csrfToken={csrfToken}>
        <ComponentList list={list} {...forwardProps} />
      </AndesProvider>
      <Hotjar {...hotjar} deviceType={deviceType} nonce={nonce} />
      <MeliMessages siteId={siteId} mainDomain={mainDomain} />
      {children}

      <TrackNewrelic newrelicTransactionName={newrelicTransactionName} />
      <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
        {`
        window.viewability.setNonceForCSP('${nonce}')
        `}
      </Script>
      <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
      <noscript />
    </div>
  );
}

Home.propTypes = {
  isLab: bool.isRequired,
  cleanPwaServiceWorkers: bool.isRequired,
  isFirstVisit: bool.isRequired,
  atf: object.isRequired,
  csrfToken: string.isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  layout: oneOf(['desktop', 'mobile']).isRequired,
  lowEnd: bool,
  restClient: object.isRequired,
  siteId: string,
  canonical: string.isRequired,
  translations: object.isRequired,
  hotjar: object,
  mainDomain: string.isRequired,
  children: node,
  newrelicTransactionName: string.isRequired,
  trackExperiments: object,
  ssrComponents: array,
  isServerSide: bool,
  preloadRecommendationsImgs: array,
  currentUser: shape({
    id: number.isRequired,
    nickname: string.isRequired,
  }),
  melidataEventData: object,
  melidataPath: string,
  i18n: shape({
    gettext: func,
  }).isRequired,
  userConsentCookie: bool.isRequired,
  criticalCH: object,
  openInApp: shape({
    shouldRedirect: bool,
  }),
  imagesPrefix: string,
  country: shape({
    locale: string,
  }),
  nonce: string,
  viewportWidth: string,
  serializeProps: string,
};

Home.defaultProps = {
  siteId: null,
  lowEnd: true,
  hotjar: null,
  children: null,
  trackExperiments: null,
  ssrComponents: null,
  isServerSide: null,
  preloadRecommendationsImgs: null,
  currentUser: null,
  melidataEventData: null,
  melidataPath: '/home',
  criticalCH: null,
  openInApp: null,
};

module.exports = injectI18n(Home);
