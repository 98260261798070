const React = require('react');
const { string } = require('prop-types');
const Image = require('../../../commons/image');
const Section = require('../../../commons/section');

const AFIP = 'afip';
const IMAGE_SRC = 'data_fiscal.png';
const HREF = 'http://qr.afip.gob.ar/?qr=eqSGL6HcpJM1lYHWSGfj-Q,,';
const MOBILE = 'mobile';

function FiscalData(props) {
  const { deviceType, siteId } = props;
  const Element = deviceType !== MOBILE ? 'div' : Section;

  return deviceType !== MOBILE && siteId === 'MLA' && (
    <Element className={AFIP}>
      <a href={HREF} target="_F960AFIPInfo" className={`${AFIP}-link`}>
        <Image lazyLoad src={IMAGE_SRC} alt={AFIP} />
      </a>
    </Element>
  );
}

FiscalData.propTypes = {
  deviceType: string,
  siteId: string,
};

FiscalData.defaultProps = {
  deviceType: null,
  siteId: null,
};

module.exports = FiscalData;
