const React = require('react');
const { shape, string, number } = require('prop-types');
const { LazyHydrate } = require('nordic/lazy');

const { useLazyComponentContext } = require('./context');

const {
  LAZYLOAD_SSRONLY,
  LAZYLOAD_WHEN_IDLE,
  LAZYLOAD_WHEN_VISIBLE,
} = require('../../../../utils/constants');

const LazyComponent = ({
  ...props
}) => {
  const { type, elementOrder, recommendation_data } = props;
  const { strategy, startInComponent, componentLoader } = useLazyComponentContext();
  const Component = componentLoader(type);

  if (elementOrder < startInComponent) {
    return <Component {...props} />;
  }

  let lazyStrategy = strategy.default;
  if (recommendation_data || type === 'recommendations-carousel-dual') {
    lazyStrategy = LAZYLOAD_WHEN_IDLE;
  } else if (strategy[type]) {
    lazyStrategy = strategy[type];
  }

  return (
    <LazyHydrate
      ssrOnly={lazyStrategy === LAZYLOAD_SSRONLY}
      whenIdle={lazyStrategy === LAZYLOAD_WHEN_IDLE}
      whenVisible={lazyStrategy === LAZYLOAD_WHEN_VISIBLE}
    >
      <Component {...props} />
    </LazyHydrate>
  );
};

LazyComponent.propTypes = {
  id: string,
  type: string,
  elementOrder: number,
  recommendation_data: shape({}),
};

module.exports = LazyComponent;
