const React = require('react');
const { createContext, useContext } = require('react');
const { node, shape, number, elementType } = require('prop-types');

const { LAZYLOAD_WHEN_VISIBLE } = require('../../../../utils/constants');

const LazyComponentContext = createContext();

const defaultConfig = {
  strategy: {
    default: LAZYLOAD_WHEN_VISIBLE,
  },
  startInComponent: 4,
};

export const LazyComponentProvider = ({ strategy, startInComponent, componentLoader, children }) => {
  const lazyConfig = {
    startInComponent: startInComponent || defaultConfig.startInComponent,
    strategy: {
      ...defaultConfig.strategy,
      ...strategy,
    },
    componentLoader,
  };

  return (
    <LazyComponentContext.Provider value={lazyConfig}>{children}</LazyComponentContext.Provider>
  );
};

export const useLazyComponentContext = () => useContext(LazyComponentContext);

LazyComponentProvider.propTypes = {
  strategy: shape({}),
  startInComponent: number,
  componentLoader: elementType.isRequired,
  children: node,
};

LazyComponentProvider.defaultProps = {
  strategy: null,
  startInComponent: null,
  children: null,
};
